
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './_bootstrap';
import { App } from './_vue'
import './simple_image_gallery';

window.app = new App({
    el: '#app',
})
