const simpleImageGalleryThumbnail = (function (element) {
  // Highlight the selected thumbnail
  const siblingImages = element.parentElement.parentElement.getElementsByClassName('simple-image-gallery-thumb-image')

  for (let item of siblingImages) {
      item.classList.add('opacity-75')
      item.classList.remove('opacity-100')
  }
  element.classList.remove('opacity-75')
  element.classList.add('opacity-100')

  // Find the main image element
  const cssClass = 'simple-image-gallery-main-image'
  const mainImages = element.parentElement.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(cssClass)

  // Set the main image 'srcset' or 'src'
  const attr = element.hasAttribute('srcset') ? 'srcset' : 'src'
  const value = element.getAttribute(attr)
  for (let image of mainImages) {
      image.setAttribute(attr, value)
  }
})

window.simpleImageGalleryThumbnail = simpleImageGalleryThumbnail
